
/* JS */

/* react */
import React from 'react';
/* third part components */

/* custom components */
import CaseStudyPage from './CaseStudyPage'; 

var gsap;
var detect = require('is-client');
if (detect()) {
	gsap = require('gsap');
	let sctr = require('gsap/ScrollTrigger');
	gsap.gsap.registerPlugin(sctr);
}

const toefromPage = props => {
	return (
		<CaseStudyPage link="toefrom" title="Sviluppo e progettazione Mobile - To&From"
		relPath="toefrom" description="To&From è una social e-gifting marketplace app che facilita la realizzazione di regali originali tramite funzioni uniche.">
		</CaseStudyPage>
	);
};

export default toefromPage;
